const image_width = 1051;
const image_height = 483;

export function mbkPosition(size, props_y) {
    const isPotrait = size.width < size.height;
   
    let resize_factor = 0.8;
    let width_factor = size.width / image_width;
    if(width_factor < 0.5) {
        width_factor = 0.5;
    }
    if(width_factor > 0.7) {
        width_factor = 0.7;
    }

    const width = image_width * width_factor * resize_factor;
    const height = image_height * width_factor * resize_factor;
    let x = size.width - width/4;
    if(!isPotrait && size.width < 700) {
        x = size.width - width/10;
    }
    const y = props_y - height - 5;

    return {x:x, y:y, width:width, height:height};
}
