import { Arrow, Circle, Group } from "react-konva";
import { useInterval } from "../utils/useInterval";


export default function Navigation(props) {
    const size = props.size;
    const direction = props.direction;
    const setDirection = props.setDirection;

    const isPotrait = size.height >= size.width;
    let leftXFactor = isPotrait ? 0.12 : 0.05;
    let leftYFactor = isPotrait ? 0.92 : 0.9;

    let leftX = size.width * leftXFactor;
    let leftY = size.height * leftYFactor;

    let rightX = size.width * (1-leftXFactor);
    let rightY = leftY;

    useInterval(()=>{
        props.offsetIncrease(-24);
    }, direction === "left" ? 30 : null);

    useInterval(()=>{
        props.offsetIncrease(24);
    }, direction === "right" ? 30 : null);

    return (
        <Group>
        <Group
        onTouchStart={(e) => {
            setDirection("left");
        }}
        onMouseDown={(e) => {
            setDirection("left");
        }}>
            <Circle
            x={leftX} y={leftY}
            width={60}
            height={60}
            fill="#353643"
            opacity={direction === "left" ? 0.95: 0.55}
            />
            <Arrow points={[leftX+15,leftY,leftX-10,leftY]}
            strokeWidth={5}
            opacity={direction === "left" ? 0.95: 0.55}
            hitStrokeWidth={0}
            stroke="#F8FAFD"
            fill="#F8FAFD"
            />
        </Group>
        <Group
        onTouchStart={(e) => {
            setDirection("right");
        }}
        onMouseDown={(e) => {
            setDirection("right");
        }}>
            <Circle
            x={rightX} y={rightY}
            width={60}
            height={60}
            fill="#353643"
            opacity={direction === "right" ? 0.95 : 0.5}
            />
            <Arrow points={[rightX-15,rightY,rightX+10,rightY]}
            strokeWidth={5}
            opacity={direction === "right" ? 0.95 : 0.5}
            hitStrokeWidth={0}
            stroke="#F8FAFD"
            fill="#F8FAFD"
            />
        </Group>
        </Group>
    );
}