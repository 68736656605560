import React from 'react';
import { Group, Image } from 'react-konva';
import useImage from 'use-image';

const url = '/assets/bg/building_bg.png';

function BuildingBackground(props) {
    const size = props.size;
    const [image] = useImage(url);

    const width = size.width;
    const height = size.height;
    
    const y = props.pavement.y - props.pavement.height/2;

    const n = Math.ceil(props.limit/width)+1;
    return (
        <Group>
            <Image fillPatternImage={image}
                fillPatternRepeat="repeat-x"
                cropWidth={size.width}
                height={height}
                width={size.width*n}
                y={y}/>
        </Group>
    );
}

export default BuildingBackground;