const image_height = 206;

export function pavementHeightAndY(windowHeight) {
    let height_factor = 1 - image_height / windowHeight;
    let resize_factor = 1;

    const height = image_height * height_factor * resize_factor;
    const maxY = windowHeight/2 + height;
    let y = windowHeight - height;
    if(y < maxY) {
        y = maxY;
    }
    return {height, y};
}
