import React, { useEffect, useRef } from 'react';
import { Group, Rect, Sprite, Text } from 'react-konva';
import useImage from 'use-image';
import { Gallery } from './Gallery';

// const url = 'https://ik.imagekit.io/mrp130/wedding_asset/ns.png';
const url = '/assets/sprites/ns.png';
const image_width = 200;
const image_height = 320;

function NS(props) {
    const size = props.size;
    const [image, status] = useImage(url);

    const ref = useRef();
    const setLoaded = props.setLoaded;
    
    useEffect(() => {
        if(status === "loaded") {
            setLoaded(prev => ({...prev, "ns": true}));
            if(ref && ref.current && !ref.current.isRunning()) {
                ref.current.start();
            }
        }
    }, [status, setLoaded]);


    const isMobile = size.width <= 768;
    const step = isMobile ? 2 : 1;
    const fps = isMobile ? 8 : 15;
    
    const resize_factor = 0.75;
    let height_factor = 1 - (image_height / size.height);
    if(height_factor < 0.5) {
        height_factor = 0.5;
    }
    if(height_factor > 1) {
        height_factor = 1;
    }
    const factor = height_factor * resize_factor;

    const width = image_width * factor;
    const height = image_height * factor;

    const animation = isFinite(props.direction) ? "idle" : props.direction;
    let idles = [];
    for(let i = 0; i < 49; i+=step) {
        idles.push(i*200,0,200,320);
    }
    let scrolls = [];
    for(let i = 0+49; i < 49+49; i+=step) {
        scrolls.push(i*200,0,200,320);
    }
    let lefts = [];
    for(let i = 0+49+49; i < 60+49+49; i+=step) {
        lefts.push(i*200,0,200,320);
    }
    let rights = [];
    for(let i = 0+60+49+49; i < 60+60+49+49; i+=step) {
        rights.push(i*200,0,200,320);
    }
    const animations = {
        idle: idles,
        left: lefts,
        right: rights,
        scroll: scrolls
    }

    let overflow = props.overflow;
    const isPotrait = size.width < size.height;
    const baseX = size.width*0.05 + width * 0.75;
    const maxOverflow = isPotrait ? size.width/2 : size.width*0.75;
    if(overflow > maxOverflow) {
        overflow = maxOverflow;
    }
    const x = baseX + overflow;
    const y = props.y-height/1.5;

    return (
        <>
        <Sprite image={image} listening={false}
        animation={animation}
        animations={animations}
        frameRate={fps}
        scale={{x: factor, y: factor}}
        ref={ref}
        y={y}
        x={x}
        />
        <Group visible={animation==="scroll" && props.overlay === "no" && status === "loaded"}
            onTouchStart={(e) => {
                props.setOverlay("zoom");
            }}
            onMouseDown={(e) => {
                props.setOverlay("zoom");
            }}
            x={x+width*0.95}
            y={y+height*0.2}
        >
            <Gallery width={40} height={64} direction={props.direction} overlay={props.overlay}/>
            <Rect
            hitStrokeWidth={0}
            stroke="#353643"
            border={5}
            height={64}
            width={40}
            />
            <Rect
            hitStrokeWidth={0}
            stroke="#353643"
            border={5}
            fill="#353643"
            height={15}
            width={40}
            cornerRadius={2}
            y={67}
            />
            <Text
            text={`Zoom`}
            fill="#F8FAFD"
            fontFamily="Josefin Slab,arial"
            fontSize={10}
            verticalAlign="middle"
            align="center"
            y={67}
            height={15}
            width={40}
            />
        </Group>
        </>
    );
}

export default NS;