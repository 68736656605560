import React from 'react';
import { Group } from 'react-konva';
import Haka from './Haka';
import Invitation from './Invitation';
import Mbk from './Mbk';
import RandomBuilding from './RandomBuilding';

function Buildings(props) {
    const y = props.pavement.y;
    
    return (
        <Group offsetX={props.offsetX}>
            <RandomBuilding y={y} size={props.size}/>
            <Haka y={y}/>
            <Mbk y={y}/>
            <Invitation y={y} size={props.size} data={props.data}/>
        </Group>
    );
}

export default Buildings;