import React from 'react';
import { Group, Text } from 'react-konva';
import SaveTheDate from './SaveTheDate';


function Invitation(props) {
    const size = props.size;
    const text = props?.data?.landing_text;
    // const text = `Dear Stephen Chrisatmaja & Partner,\nyou are invited to our wedding`;

    const isPotrait = size.width < size.height;
    let boxPosition = {
        x: 20,
        y: size.height/2 + (isPotrait ? -70 : 0)
    };

    if(boxPosition.y > props.y - 120) {
        boxPosition.y = props.y - 120;
    }

    const width = isPotrait ? size.width-40 : size.width/2;

    return (
        <Group>
        <Text
        text={text}
        lineHeight={1.2}
        fontSize={20}
        fontFamily="Josefin Slab,arial"
        x={boxPosition.x}
        y={boxPosition.y}
        align="left"
        fill="#353643"
        width={width}
        />
        <SaveTheDate boxPosition={boxPosition} size={size} data={props.data}/>
        </Group>
    );
}

export default Invitation;