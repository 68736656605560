import React from 'react';
import { Group } from 'react-konva';
import Navigation from './Navigation';


function Actions(props) {
    const size = props.size;
    
    return (
        <Group>
            <Navigation size={size} offsetIncrease={props.offsetIncrease} 
            direction={props.direction}
            setDirection={props.setDirection}/>
        </Group>
    );
}

export default Actions;