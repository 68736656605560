import { useState } from "react";
import { useInterval } from "./useInterval";

const cloud_width = 1280;

const useCloudPosition = (isMobile) => {
  const [position, setPosition] = useState(0);

  useInterval(()=>{
    let newPos = position-1;
    if(newPos * -1 >= cloud_width) {
      newPos = (newPos*-1-cloud_width)*-1;
    }
    setPosition(newPos);
  }, isMobile ? null : 150);

  return position;
};

export default useCloudPosition;