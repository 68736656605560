import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

const url = '/assets/bg/sky.png';

function Sky(props) {
    const size = props.size;
    const [image] = useImage(url);
    
    return (
        <Image image={image} width={size.width*7} height={size.height}/>
    );
}

export default Sky;