import React from 'react';
import { Group, Image } from 'react-konva';
import useImage from 'use-image';

const url = '/assets/bg/shadow.png';
const image_width = 1280;
const image_height = 720;

function BuildingShadow(props) {
    const size = props.size;
    const [image] = useImage(url);
    
    let height_factor = size.height / image_height;
    if(height_factor > 1) {
        height_factor = 1;
    }

    const width = image_width * height_factor;
    const height = image_height * height_factor;
    const y = size.height/2 - height/2;

    const n = Math.ceil(props.limit/width)+1;
    return (
        <Group>
            {[...Array(n).keys()].map((i) => 
                <Image key={i} image={image}
                    height={height}
                    width={width}
                    x={width*i}
                    y={y+height/4}
                    />
            )}
            {[...Array(n).keys()].map((i) => 
                <Image key={i} image={image}
                    height={height}
                    width={width}
                    x={width*i}
                    y={y}
                    />
            )}
        </Group>
    );
}

export default BuildingShadow;