import { useState } from "react";
import { Group, Image } from "react-konva";
import useImage from "use-image";
import { useInterval } from "../utils/useInterval";

// const url_format = `https://ik.imagekit.io/mrp130/tr:w-{0},h-{1},q-100,dpr-2,cm-pad_resize,bg-353643/prewed/{2}.jpg`;
const url_format = `https://ik.imagekit.io/mrp130/tr:w-{0},h-{1},q-100,dpr-2,cm-pad_resize,bg-00000066/prewed/{2}.jpg`;
// const url_format = `https://ik.imagekit.io/mrp130/tr:w-{0},h-{1},q-100,dpr-2,fo-center/prewed/{2}.jpg`;

export function ZoomGallery(props) {
    const [scroll, setScroll] = useState(false);
    const [offset, setOffset] = useState({offset:0, index:0});

    useInterval(()=>{
        setScroll(true);
    }, props.overlay === "zoom" && props.direction === "scroll" ? 6000 : null);

    const n = 16;
    useInterval(()=>{
        let newOffset = offset.offset+props.height/16;
        const index = Math.floor(offset.offset/props.height);
        if(newOffset >= props.height * n) {
            newOffset = 0;
        }
        if(index !== offset.index) {
            newOffset = index * props.height;
            setScroll(false);
        }
        setOffset({offset: newOffset, index: index});
    }, props.overlay === "zoom" && props.direction === "scroll" && scroll ? 100 : null);

    return (
        <Group visible={props.overlay === "zoom"}
            x={props.x} y={props.y} 
            offsetY={offset.offset} clipX={0} clipY={offset.offset} 
            clipWidth={props.width} clipHeight={props.height}
            listening={false}
        >
        {[...Array(n+2).keys()].map((i) => 
            <GalleryImage key={i+1} index={(i%n)+1}
                width={props.width}
                height={props.height}
                y={i*props.height}
                listening={false}
            />
        )}
        </Group>
    );
}

const GalleryImage = ({index, width, height, y}) => {
    const [image] = useImage(url_format.format(width, height, index));
    return <Image listening={false} image={image} width={width} height={height} y={y}/>
};