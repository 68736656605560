import React from 'react';
import { Group, Rect, Text } from 'react-konva';

function SaveTheDate(props) {
    const size = props.size;
    const link = props?.data?.reservation_link;
    //const link = "https://wa.me/6285287619148?text=hai%20Michael,%20saya%20mau%20konfirmasi%20kehadiran%20acara%20pernikahan,%20saya%20datang%20dengan%20...%20orang";
    
    const isPotrait = size.width < size.height;
    let boxPosition = !isPotrait ? {
        x: props.boxPosition.x + size.width/2+5,
        y: props.boxPosition.y
    } : {
        x: size.width/2-120,
        y: props.boxPosition.y+80
    };

    return (
        <Group>
        <Text
        text={`Saturday 18.06.2022`}
        fontSize={26}
        fontFamily="Josefin Slab"
        x={boxPosition.x}
        y={boxPosition.y}
        align="center"
        height={30}
        width={244}
        fill="#353643"
        />
        <Group
            onMouseDown={(e)=>{
                setTimeout(() => {
                    let win = window.open(link, "_blank");
                    if(!win || win.closed || typeof win.closed=='undefined') {
                        alert("Popup blocked. Please update setting or change browser");
                    }
                });
            }}
            onTouchStart={(e)=>{
                setTimeout(() => {
                    let win = window.open(link, "_blank");
                    if(!win || win.closed || typeof win.closed=='undefined') {
                        alert("Popup blocked. Please update setting or change browser");
                    }
                });
            }}
        >
            <Rect 
            fill="#353643"
            x={boxPosition.x}
            y={boxPosition.y+30}
            height={30}
            width={120}
            cornerRadius={5}
            hitStrokeWidth={0}
            />
            <Text
            hitStrokeWidth={0}
            text={`Reservation`}
            fill="#F8FAFD"
            fontFamily="Josefin Slab"
            fontSize={16}
            verticalAlign="middle"
            align="center"
            x={boxPosition.x}
            y={boxPosition.y+30}
            height={30}
            width={120}
            />
        </Group>
        <Group
            onMouseDown={(e)=>{
                setTimeout(() => {
                    let win = window.open("https://calendar.google.com/event?action=TEMPLATE&text=Michael%20and%20Netty%20Wedding&tmeid=MGc0Z3NuYTA4NDg1ZW44NDdicmlpZml0aGMgbWlrYWVscGhhbmdAbQ&tmsrc=mikaelphang%40gmail.com", "_blank");
                    if(!win || win.closed || typeof win.closed=='undefined') {
                        alert("Popup blocked. Please update setting or change browser");
                    }
                });
            }}
            onTouchStart={(e)=>{
                setTimeout(() => {
                    let win = window.open("https://calendar.google.com/event?action=TEMPLATE&text=Michael%20and%20Netty%20Wedding&tmeid=MGc0Z3NuYTA4NDg1ZW44NDdicmlpZml0aGMgbWlrYWVscGhhbmdAbQ&tmsrc=mikaelphang%40gmail.com", "_blank");
                    if(!win || win.closed || typeof win.closed=='undefined') {
                        alert("Popup blocked. Please update setting or change browser");
                    }
                });
            }}
        >
            <Rect
            hitStrokeWidth={0}
            fill="#353643"
            x={boxPosition.x+125}
            y={boxPosition.y+30}
            height={30}
            width={120}
            cornerRadius={5}
            />
            <Text
            hitStrokeWidth={0}
            text={`Save The Date`}
            fill="#F8FAFD"
            fontFamily="Josefin Slab"
            fontSize={16}
            verticalAlign="middle"
            align="center"
            x={boxPosition.x+125}
            y={boxPosition.y+30}
            height={30}
            width={120}
            />
        </Group>
        </Group>
    );
}

export default SaveTheDate;