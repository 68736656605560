import React from 'react';
import { Group, Image } from 'react-konva';
import useImage from 'use-image';
import useRandomBuildings from '../utils/useRandomBuildings';

const urls = [
    '/assets/building/tree_1.png',
    '/assets/building/tree_2.png',
    '/assets/building/building_1.png',
    '/assets/building/building_2.png',
    '/assets/building/building_3.png',
    '/assets/building/house_1.png',
    '/assets/building/house_2.png',
];

function RandomBuilding(props) {
    const images = [];
    let image;

    [image] = useImage(urls[0]);
    images.push(image);
    [image] = useImage(urls[1]);
    images.push(image);
    [image] = useImage(urls[2]);
    images.push(image);
    [image] = useImage(urls[3]);
    images.push(image);
    [image] = useImage(urls[4]);
    images.push(image);
    [image] = useImage(urls[5]);
    images.push(image);
    [image] = useImage(urls[6]);
    images.push(image);
    
    const results = useRandomBuildings(props.size, props.y);
    return (
        <Group>
            {results.map((res, i) => 
                <Image key={i} image={images[res.idx]}
                    height={res.height}
                    width={res.width}
                    x={res.x}
                    y={res.y}
                    listening={false}
                    />
            )}
        </Group>
    );
}

export default RandomBuilding;