import React from 'react';
import { Group, Image } from 'react-konva';
import useImage from 'use-image';
import useCloudPosition from '../utils/useCloudPosition';

const url = '/assets/bg/cloud.png';
const image_height = 720;

function Cloud(props) {
    const [image] = useImage(url);
   
    const size = props.size;
    const isMobile = size.width <= 768;
    const position = useCloudPosition(isMobile);

    let height_factor = size.height / image_height;
    if(height_factor > 1) {
        height_factor = 1;
    }

    const height = image_height * height_factor;
    
    const n = Math.ceil(props.limit/size.width)+3;
    return (
        <Group x={position}>
            <Image fillPatternImage={image}
                fillPatternRepeat="repeat-x"
                height={height}
                width={size.width*n}/>
        </Group>
    );
}

export default Cloud;