import { Image, Group, Rect } from "react-konva";
import useImage from "use-image";
import { Info } from "./Info";
import { ZoomGallery } from "./ZoomGallery";

function Overlay(props) {
    const size = props.size;
    
    const [closeImage] = useImage("/assets/button/close.png");

    const isPotrait = size.width < size.height;
    let width = Math.floor(size.width * 0.85);
    let height = Math.floor(width * 1.618);
    while(height >= size.height * 0.9) {
        width = Math.floor(width * 0.9);
        height = Math.floor(width * 1.618);
    }
    if(!isPotrait) {
        height = Math.floor(size.height * 0.9);
        width = Math.floor(height * 1.618);
        while(width >= size.width * 0.9) {
            height = Math.floor(height * 0.9);
            width = Math.floor(height * 1.618);
        }
    }

    const closeButtonSize = isPotrait ? height/15 : width/20;
    return (
        <Group visible={props.overlay !== "no"}>
            <Rect
            fill="#191919"
            opacity={0.8}
            x={0}
            y={0}
            width={size.width}
            height={size.height}
            />
            <Info visible={props.overlay === "info"}
                overlay={props.overlay}
                x={size.width/2-width/2} y={size.height/2-height/2}
                direction={props.direction}
                width={width}
                height={height}
                data={props.data}
            />
            <ZoomGallery visible={props.overlay === "zoom"}
                overlay={props.overlay}
                x={size.width/2-width/2} y={size.height/2-height/2}
                direction={props.direction}
                width={width}
                height={height}
            />
            <Image image={closeImage} visible={props.closeable}
                x={size.width/2+width/2-closeButtonSize/2} y={size.height/2-height/2-closeButtonSize/2}
                width={closeButtonSize}
                height={closeButtonSize}
                onTouchStart={(e) => {
                    props.setOverlay("no");
                }}
                onMouseDown={(e) => {
                    props.setOverlay("no");
                }}
            />
        </Group>
    );
}

export default Overlay;