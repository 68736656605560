import React from 'react';
import { Group } from 'react-konva';
import useWindowSize from '../utils/useWindowSize';
import BuildingBackground from './BuildingBackground';
import BuildingShadow from './BuildingShadow';
import Cloud from './Cloud';
import Pavement from './Pavement';
import Sky from './Sky';


function Backgrounds(props) {
    const size = useWindowSize();
    
    const height = props.pavement.height;
    const y = props.pavement.y;

    return (
        <Group listening={false} offsetX={props.offsetX}>
            <Sky size={size} limit={props.limit}/>
            <Cloud size={size} limit={props.limit}/>
            <BuildingShadow size={size} pavement={props.pavement} limit={props.limit}/>
            <BuildingBackground size={size} pavement={props.pavement} limit={props.limit}/>
            <Pavement size={size} height={height} y={y} limit={props.limit}/>
        </Group>
    );
}

export default Backgrounds;