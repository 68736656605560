import React from 'react';
import { Group } from 'react-konva';
import useWindowSize from '../utils/useWindowSize';
import RP from './RP';
import NS from './NS';


function Characters(props) {
    const size = useWindowSize();
    
    const y = props.pavement.y;
   
    return (
        <Group>
            <NS size={size} y={y} 
                direction={props.direction} overflow={props.overflow}
                overlay={props.overlay} setOverlay={props.setOverlay}
                setLoaded={props.setLoaded}
            />
            <RP size={size} y={y} direction={props.direction} overflow={props.overflow}
                setLoaded={props.setLoaded}
            />
        </Group>
    );
}

export default Characters;