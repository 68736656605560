import React from 'react';
import { Group, Image, Rect, Text } from 'react-konva';
import useImage from 'use-image';
import { hakaPosition } from '../utils/hakaPosition';
import useWindowSize from '../utils/useWindowSize';

const url = '/assets/building/haka.png';

function Haka(props) {
    const size = useWindowSize();
    const [image] = useImage(url);
    
    const hakaPos = hakaPosition(size, props.y);
    const x = hakaPos.x;
    const y = hakaPos.y;
    const width = hakaPos.width;
    const height = hakaPos.height;

    const isPotrait = size.width < size.height;
    const boxPosition = isPotrait ? {
        x: x+width*0.3,
        y: y-70
    } : {
        x: x-width*0.5,
        y: y
    };

    return (
        <>
        <Group listening={false}>
        <Rect
            x={boxPosition.x-width*3} listening={false}
            width={width*6}
            height={size.height}
            fillLinearGradientStartPoint={{ x: 0, y: size.height/2 }}
            fillLinearGradientEndPoint={{ x: width*3, y: size.height/2 }}
            fillLinearGradientColorStops={[0, 'rgba(0,0,0,0)', 1, "rgba(0,0,0,0.8)"]}
          />
        <Image image={image}
            height={height}
            width={width}
            x={x}
            y={y}
            listening={false}
        />
        </Group>
        <Text
        hitStrokeWidth={0}
        listening={false}
        text={`Haka Restaurant\n18.06.2022 | 18:00`}
        fontSize={20}
        fontFamily="Josefin Slab,arial"
        x={boxPosition.x}
        y={boxPosition.y}
        align="center"
        fill="#F8FAFD"
        />
        <Group
            onMouseDown={(e)=>{
                setTimeout(() => {
                    let win = window.open("https://g.page/Haka-Restaurant", "_blank");
                    if(!win || win.closed || typeof win.closed=='undefined') {
                        alert("Popup blocked. Please update setting or change browser");
                    }
                });
            }}
            onTouchStart={(e)=>{
                setTimeout(() => {
                    let win = window.open("https://g.page/Haka-Restaurant", "_blank");
                    if(!win || win.closed || typeof win.closed=='undefined') {
                        alert("Popup blocked. Please update setting or change browser");
                    }
                });
            }}
        >
            <Rect
            hitStrokeWidth={0}
            fill="#F8FAFD"
            x={boxPosition.x+25}
            y={boxPosition.y+45}
            height={30}
            width={100}
            cornerRadius={5}
            />
            <Text
            hitStrokeWidth={0}
            text={`Open Map`}
            fill="#353643"
            fontFamily="Josefin Slab,arial"
            fontSize={14}
            verticalAlign="middle"
            align="center"
            x={boxPosition.x+25}
            y={boxPosition.y+45}
            height={30}
            width={100}
            />
        </Group>
        </>
    );
}

export default Haka;