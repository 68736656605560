import { useEffect, useState } from "react";
import { mbkPosition } from '../utils/mbkPosition';
import { hakaPosition } from '../utils/hakaPosition';

const sizes = [
  {w: 142, h: 296},
  {w: 206, h: 384},
  {w: 265, h: 530},
  {w: 465, h: 328},
  {w: 419, h: 604},
  {w: 292, h: 302},
  {w: 292, h: 288}
];

const anchor_height = 600;

const useRandomBuildings = (size, y) => {
  const mbkPos = mbkPosition(size, y);
    const hakaPos = hakaPosition(size, y);

    let resize_factor = 1 - (anchor_height / size.height);
    if(resize_factor < 0.3) {
        resize_factor = 0.3;
    }
    if(resize_factor > 1) {
        resize_factor = 1;
    }

    let resized = [];
    for(let i = 0; i < sizes.length; i++) {
      resized.push({
        w: sizes[i].w * resize_factor,
        h: sizes[i].h * resize_factor
      });
    }

    const [results, setResults] = useState([]);
    useEffect(()=> {
        const startX = mbkPos.x + mbkPos.width;
        const endX = hakaPos.x - 50;
        const _results = [];
        for(let _x = startX; _x < endX+hakaPos.width; _x += random(-5,5)) {
          const idx = random(2,6);
          const width = resized[idx].w;
          const height = resized[idx].h;
          _x += width*(random(8,10)/10);
          const _y = y - height - 5;
          _results.push({
              x: _x,
              y: _y,
              width: width,
              height: height,
              idx: idx
          });
        }

        for(let _x = startX + 50; _x < endX; _x += 150) {
            const idx = random(0,1);
            const width = resized[idx].w;
            const height = resized[idx].h;
            const _y = y - height - 5;
            _results.push({
                x: _x,
                y: _y,
                width: width,
                height: height,
                idx: idx
            });
        }

        setResults(_results);
    }, [size, y]);

    return results;
};

function random(lower, upper) {
  return Math.floor(Math.random() * (upper-lower+1)) + lower;
}

export default useRandomBuildings;