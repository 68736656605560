const image_width = 614;
const image_height = 461;

export function hakaPosition(size, props_y) {
    let resize_factor = 0.8;
    let width_factor = size.width / image_width;
    if(width_factor < 0.3) {
        width_factor = 0.3;
    }
    if(width_factor > 0.7) {
        width_factor = 0.7;
    }


    const width = image_width * width_factor * resize_factor;
    const height = image_height * width_factor * resize_factor;
    const x = max(size.width * 2.5, width * 5) - 5;
    const y = props_y - height - 5;

    return {x:x, y:y, width:width, height:height};
}

function max(a, b) {
    return a > b ? a : b;
}