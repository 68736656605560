import React, { useEffect, useRef } from 'react';
import { Sprite } from 'react-konva';
import useImage from 'use-image';

// const url = 'https://ik.imagekit.io/mrp130/wedding_asset/rp.png';
const url = '/assets/sprites/rp.png';

const image_height = 320;

function RP(props) {
    const size = props.size;
    const [image, status] = useImage(url);

    const ref = useRef();   
    const setLoaded = props.setLoaded;
    
    useEffect(() => {
        if(status === "loaded") {
            setLoaded(prev => ({...prev, "rp": true}));
            if(ref && ref.current && !ref.current.isRunning()) {
                ref.current.start();
            }
        }
    }, [status, setLoaded]);
    
    useEffect(() => {
        if(status === "loaded") {
            if(ref && ref.current && !ref.current.isRunning()) {
                ref.current.start();
            }
        }
    }, [status]);

    const isMobile = size.width <= 768;
    const step = isMobile ? 2 : 1;
    const fps = isMobile ? 8 : 15;

    const resize_factor = 0.9;
    let height_factor = 1 - (image_height / size.height);
    if(height_factor < 0.5) {
        height_factor = 0.5;
    }
    if(height_factor > 1) {
        height_factor = 1;
    }

    const factor = height_factor * resize_factor;

    const height = image_height * factor;

    const animation = isFinite(props.direction) ? "idle" : props.direction;
    let idles = [];
    for(let i = 0; i < 49; i+=step) {
        idles.push(i*200,0,200,320);
    }
    let lefts = [];
    for(let i = 0+49; i < 60+49; i+=step) {
        lefts.push(i*200,0,200,320);
    }
    let rights = [];
    for(let i = 0+60+49; i < 60+60+49; i+=step) {
        rights.push(i*200,0,200,320);
    }
    const animations = {
        idle: idles,
        left: lefts,
        right: rights,
        scroll: idles
    }

    let overflow = props.overflow;
    const isPotrait = size.width < size.height;
    const baseX = size.width*0.05;
    const maxOverflow = isPotrait ? size.width/2 : size.width*0.75;
    if(overflow > maxOverflow) {
        overflow = maxOverflow;
    }
    const x = baseX + overflow;

    return (
        <Sprite image={image} listening={false}
        animation={animation}
        animations={animations}
        frameRate={fps}
        scale={{x: factor, y: factor}}
        ref={ref}
        y={props.y-height/1.6}
        x={x}
        />
    );
}

export default RP;