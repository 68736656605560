import { Group, Image, Rect, Text } from "react-konva";
import useImage from "use-image";

// const text = `
// Yth. Stephen Chrisatmaja dan pasangan,

// Bersamaan dengan ini, kami, Michael Reynaldo Phangtriastu dan Netty Setiawan, bermaksud mengundang Bapak/Ibu/Saudara/i ke acara pernikahan kami.

// Tanpa mengurangi rasa hormat, dikarenakan situasi pandemi covid-19, kami mengadakan acara resepsi makan meja dengan jumlah tamu terbatas sesuai dengan anjuran pemerintah.

// Mohon bantuannya untuk mengabarkan jumlah tamu yang akan datang lewat tombol reservasi.

// Atas kehadiran dan doa restu Bapak/Ibu/Saudara/i kami ucapkan terima kasih.`;

const url = '/assets/signature.png';
const image_width = 166;
const image_height = 53;

export function Info(props) {
    const padding = 24;
    const text = props?.data?.info_text;
    const [image] = useImage(url);

    const width = image_width * 0.5;
    const height = image_height * 0.5;

    return (
        <Group visible={props.overlay === "info"}
            x={props.x} y={props.y} 
            clipX={0} clipY={0} 
            clipWidth={props.width} clipHeight={props.height}>
        <Rect
            fill="#F8FAFD"
            x={0}
            y={0}
            width={props.width}
            height={props.height}
        />
        <Text
            x={padding}
            y={padding}
            fontFamily="Josefin Sans,Arial"
            fontSize={16}
            lineHeight={1.2}
            fill="##030300"
            text={text}
            width={props.width-padding*2}
            height={props.height-padding*2}
        />
        <Image image={image}
            height={height}
            width={width}
            x={props.width-width-padding/2}
            y={props.height-height-padding/2}
            listening={false}
        />
        </Group>
    );
}
