import axios from "axios";
import { useEffect, useState } from "react";

export default function useData(setCloseable) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");

    const [data, setData] = useState(null);

    useEffect(() => {
        const invalid_data = {
            "reservation_link": "",
            "landing_text": "Invalid id, please use correct URL or contact Michael / Netty",
            "info_text": "Invalid id, please use correct URL or contact Michael / Netty"
        };

        const server_error = {
            "reservation_link": "",
            "landing_text": "Server error, please try again later or contact Michael / Netty",
            "info_text": "Server error, please try again later or contact Michael / Netty"
        };

        if(id === null || id === undefined) {
            setData(invalid_data);
        }
        axios({
            url: process.env.REACT_APP_BACKEND_URL + '/guests/' + id,
            timeout: 7000,
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: false,
            method: "get"
        }).then(response => {
                setData({
                    "reservation_link": response.data.reservation_link,
                    "info_text": response.data.info_text,
                    "landing_text": response.data.landing_text,
                });
                setCloseable(true);
            }).catch((e)=> {
                console.error("catch", e);
                if(e?.response?.status >= 400 && e?.response?.status <500) {
                    setData(invalid_data);
                    return;
                }
                setData(server_error);
            });
    }, []);

    return data;
}