import React from 'react';
import { Group, Image } from 'react-konva';
import useImage from 'use-image';
    
const url = '/assets/bg/pavement.png';

function Pavement(props) {
    const size = props.size;
    const height = props.height;
    const y = props.y;

    const [image] = useImage(url);

    const n = Math.ceil(props.limit/size.width)+1;

    return (
        <Group y={y}>
            {[...Array(n).keys()].map((i) => 
                <Image key={i} image={image}
                    height={height}
                    width={size.width}
                    x={size.width*i}
                    />
            )}
        </Group>
    );
}

export default Pavement;