import React from 'react';
import { Group, Image, Rect, Text } from 'react-konva';
import useImage from 'use-image';
import { mbkPosition } from '../utils/mbkPosition';
import useWindowSize from '../utils/useWindowSize';

const url = '/assets/building/mbk.png';

function Mbk(props) {
    const size = useWindowSize();

    const isPotrait = size.width < size.height;
    const [image] = useImage(url);
    
    const mbkPos = mbkPosition(size, props.y);
    const x = mbkPos.x;
    const y = mbkPos.y;
    const width = mbkPos.width;
    const height = mbkPos.height;

    const boxPosition = isPotrait ? {
        x: x + width * 0.5,
        y: y - height/8
    } : {
        x: x + width * 0.6,
        y: y - 5
    };

    return (
        <>
        <Group listening={false}>
        <Image image={image}
            height={height}
            width={width}
            x={x}
            y={y}
            listening={false}
        />
        <Text
        listening={false}
        text={`Maria Bunda Karmel\n18.06.2022 | 10:00`}
        fontSize={20}
        fontFamily="Josefin Slab,arial"
        x={boxPosition.x}
        y={boxPosition.y}
        align="center"
        height={120}
        width={300}
        fill="#353643"
        />
        </Group>
        <Group
            onMouseDown={(e)=>{
                setTimeout(() => {
                    let win = window.open("https://goo.gl/maps/TqFaY8dscBqDWue99", "_blank");
                    if(!win || win.closed || typeof win.closed=='undefined') {
                        alert("Popup blocked. Please update setting or change browser");
                    }
                });
            }}
            onTouchStart={(e)=>{
                setTimeout(() => {
                    let win = window.open("https://goo.gl/maps/TqFaY8dscBqDWue99", "_blank");
                    if(!win || win.closed || typeof win.closed=='undefined') { 
                        alert("Popup blocked. Please update setting or change browser");
                    }
                });
            }}
        >
            <Rect
            hitStrokeWidth={0}
            fill="#353643"
            x={boxPosition.x+100}
            y={boxPosition.y+45}
            height={30}
            width={100}
            cornerRadius={5}
            />
            <Text
            hitStrokeWidth={0}
            text={`Open Map`}
            fill="#F8FAFD"
            fontFamily="Josefin Slab,arial"
            fontSize={14}
            verticalAlign="middle"
            align="center"
            x={boxPosition.x+100}
            y={boxPosition.y+45}
            height={30}
            width={100}
            />
        </Group>
        </>
    );
}

export default Mbk;