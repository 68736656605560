import React, { useEffect, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import { RingLoader } from 'react-spinners';
import Actions from './action/Actions';
import Backgrounds from './background/Backgrounds';
import Buildings from './building/Buildings';
import Characters from './character/Characters';
import Overlay from './overlay/Overlay';
import useData from './utils/useData';
import { hakaPosition } from './utils/hakaPosition';
import { pavementHeightAndY } from './utils/pavementHeightAndY';
import useDirection from './utils/useDirection';
import useWindowSize from './utils/useWindowSize';
import FontFaceObserver from 'fontfaceobserver';

const App = () => {
  const size = useWindowSize();
  const isPotrait = size.width < size.height;
  const [offset, setOffset] = useState({x:0, overflow: isPotrait ? size.width*0.15 : size.width*0.7});
  const [overlay, _setOverlay] = useState("info");
  const [loaded, setLoaded] = useState({
    "ns": false,
    "rp": false,
    "font": false,
    "font_2": false,
  });

  useEffect(() => {
    const font = new FontFaceObserver('Josefin Slab');
    const font2 = new FontFaceObserver('Josefin Sans');
    font.load().then(() => {
      setLoaded(prev => ({...prev, "font": true}));
    }).catch(() => {
      setLoaded(prev => ({...prev, "font": true}));
    });
    font2.load().then(() => {
      setLoaded(prev => ({...prev, "font_2": true}));
    }).catch(() => {
      setLoaded(prev => ({...prev, "font_2": true}));
    });
  }, []);

  const [direction, setDirection] = useDirection();
  const [closeable, setCloseable] = useState(false);
  const setOverlay = (val) => {
    if(closeable) _setOverlay(val);
  }

  const data = useData(setCloseable);

  const pavement = pavementHeightAndY(size.height);

  const hakaPos = hakaPosition(size, pavement.y);
  const limit = hakaPos.x + hakaPos.width + 10;

  const maxOffset = hakaPos.x + hakaPos.width - size.width + 50;
  const offsetIncrease = (incr) => {
    let newOverflow = offset.overflow;
    let newOffset = offset.x + incr;
    if(newOffset < 0) {
      newOffset = 0;
      newOverflow += incr;
      if(newOverflow < 0) newOverflow = 0;
    }
    if(newOffset > maxOffset) {
      newOffset = maxOffset;
      newOverflow += incr;
      if(newOverflow > size.width) newOverflow = size.width;
    }

    if(incr < 0 && newOverflow > 0) {
      newOverflow += incr/4;
      if(newOverflow < 0) newOverflow = 0;
    }

    setOffset({x:newOffset, overflow: newOverflow});
  }
  
  const isLoadComplete = loaded["ns"] && loaded["rp"] && loaded["font"] && loaded["font_2"];
  if(!loaded["font"] || !loaded["font_2"]) {
    return <RingLoader loading={!isLoadComplete} css={loaderCss} size={50} color={"#E2C275"}/>
  }
  
  return (
    <>
    <RingLoader loading={!isLoadComplete} css={loaderCss} size={50} color={"#E2C275"}/>
    <Stage visible={isLoadComplete}
      width={size.width}
      height={size.height}
      draggable={false}
      onTouchEnd={(e) => {
        if(direction === "scroll") return;
        setDirection(Date.now());
      }}
      onMouseLeave={(e) => {
        if(direction === "scroll") return;
        setDirection(Date.now());
      }}
      onMouseUp={(e) => {
        if(direction === "scroll") return;
        setDirection(Date.now());
      }}
    >
    <Layer>
      <Backgrounds pavement={pavement} offsetX={offset.x} limit={limit}/>
    </Layer>
    <Layer>
      <Buildings pavement={pavement} offsetX={offset.x} size={size} data={data}/>
    </Layer>
    <Layer>
      <Characters pavement={pavement} direction={direction} overflow={offset.overflow} overlay={overlay} setOverlay={setOverlay} setLoaded={setLoaded}/>
    </Layer> 
    <Layer>
      <Actions size={size} offsetIncrease={offsetIncrease} direction={direction} setDirection={setDirection}/>
    </Layer> 
    <Layer>
      <Overlay size={size} direction={direction} 
        overlay={overlay} setOverlay={setOverlay}
        closeable={closeable}
        data={data}
      />
      </Layer>
    </Stage>
    </>
  );
};

const loaderCss = {
  "display": "block",
  "margin": "0 auto",
  "position": "absolute",
  "top": "calc(50% - 25px)",
  "left": "calc(50% - 25px)"
}

String.prototype.format = function () {
  var args = arguments;
  return this.replace(/{([0-9]+)}/g, function (match, index) {
    return typeof args[index] === 'undefined' ? match : args[index];
  });
};

export default App;