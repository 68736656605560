import { useEffect, useState } from "react";
import { useInterval } from "./useInterval";

const useDirection = () => {
  const [direction, setDirection] = useState(Date.now()-1000);

  const handleKeyDown = (e) => {
    const c = e.keyCode;
    if(c === 37 || c === 65) {
      setDirection("left");
    }
    if(c === 39 || c === 68) {
      setDirection("right");
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  const handleKeyUp = () => {
    if(direction === "scroll") return;
    setDirection(Date.now());
  }
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  }, []);

  useInterval(()=>{
    // if(direction === "scroll") setDirection(Date.now());
    if(!isFinite(direction)) return;

    if(Date.now() - direction > 1500) {
      setDirection("scroll");
    }
  }, 1500);

  return [direction, setDirection];
};

export default useDirection;